export const SET_ALERT = "SET_ALERT";
export const REMOVE_ALERT = "REMOVE_ALERT";
export const USER_LOADED = "USER_LOADED";
export const AUTH_ERROR = "AUTH_ERROR";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT = "LOGOUT";
export const GET_NEWS = "GET_NEWS";
export const GET_NEW = "GET_NEW";
export const GET_EVENTS = "GET_EVENTS";
export const GET_MORE_NEWS = "GET_MORE_NEWS";
export const GET_TALLERES = "GET_TALLERES";
export const GET_TALLER_ITEM = "GET_TALLER_ITEM";
